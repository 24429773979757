import React from "react";
import "./style.css";
import ItemStep from "./ItemStep";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { payment_gate_way, trackingOrder } from "../../services/action";
import TokenLang from "../../Hook/TokenLang";
import SpinnerLoding from "../../lib/SpinnerLoding/SpinnerLoding";
import { t } from "i18next";
export default function FollowOrderTrakening() {
  const param = useParams();
  const dispatch = useDispatch();
  const dataUser = JSON.parse(localStorage.getItem("login"));

  const [token, lang] = TokenLang();
  useEffect(() => {
    dispatch(trackingOrder(param.id, token, lang));
  }, [lang]);
  const data = useSelector((state) => state.trackingOrder);
  useEffect(() => {
    if (data && data.status && data.data.order.payment_method === "Cash") {
      dispatch(
        payment_gate_way(data.data.order.uuid, dataUser.data.accessToken)
      );
    }
  }, [data]);
  const paymentGateway = useSelector((state) => state.payment_gate_way);
  return (
    <>
      <div
        className="container-xxl mb-5 h_foolow_order   d-flex
    justify-content-center"
      >
        {data.status ? (
          <section className="root">
            <div className="order-track">
              {data &&
                data.data.order_histories.map((item, index) => (
                  <ItemStep
                    activeCheck={
                      data.data.order_histories.length === index + 1
                        ? true
                        : false
                    }
                    data={item}
                    endState={
                      data.data.order_histories.length === index + 1
                        ? true
                        : false
                    }
                  />
                ))}
            </div>
          </section>
        ) : (
          <SpinnerLoding />
        )}
      </div>
      {paymentGateway && paymentGateway.status && (
        <div className="text-center">
          <a
            href={paymentGateway.data.url}
            className="   second_btn rounded-3 border-0 "
          >
            {t("Payment Now")}
          </a>
        </div>
      )}
    </>
  );
}
