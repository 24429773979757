import React from "react";
import "./style.css";
import { useState } from "react";
import InputSelect from "../Inputs/InputSelect";
import InputTextArea from "../Inputs/InputTextArea";
import ToggleIconArrow from "../ArrowToggleIcon/ToggleIconArrow";
import Maping from "./Maping";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { countries, user_cities, user_districts } from "../../services/action";
import TokenLang from "../../Hook/TokenLang";
import { t } from "i18next";
import LinksCustom from "../Links/LinksCustom";

export default function FormShippingInformation({ handleNext, handleBack }) {
  const [token, lang] = TokenLang();
  const [latitude, setlatitude] = useState("");
  const [longitude, setlongitude] = useState("");
  const [state, setstate] = useState(true);
  const [addressDedails, setaddressDedails] = useState("");
  const [valueAll, setvalueAll] = useState({
    country: "",
    citie: "",
    district: "",
  });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(countries(lang));
  }, [lang]);
  let countersdata = useSelector((state) => state.countries);
  let userCities = useSelector((state) => state.user_cities);
  let userdistricts = useSelector((state) => state.user_districts);
  let countriesOptions = [];
  let CitiesOptions = [];
  let districtsOptions = [];
  if (countersdata.status) {
    countersdata.data.forEach((element) => {
      countriesOptions.push({ label: element.name, value: element.id });
    });
  }

  if (userCities.status) {
    userCities.data.forEach((element) => {
      CitiesOptions.push({ label: element.name, value: element.id });
    });
  }
  if (userdistricts.status) {
    userdistricts.data.forEach((element) => {
      districtsOptions.push({ label: element.name, value: element.id });
    });
  }
  const getcountries = (e) => {
    dispatch({ type: "user_districts", paylod: [] });
    dispatch({ type: "user_cities", paylod: [] });

    dispatch(user_cities(lang, e.value));

    setvalueAll({
      ...valueAll,
      country: e.value,
    });
  };
  const getCities = (e) => {
    dispatch({ type: "user_districts", paylod: [] });

    dispatch(user_districts(lang, e.value));
    setvalueAll({
      ...valueAll,
      citie: e.value,
    });
  };
  const getdistricts = (e) => {
    setvalueAll({
      ...valueAll,
      district: e.value,
    });
  };
  const saveAddress = () => {
    localStorage.setItem(
      "Address_desatils",
      JSON.stringify({
        country: valueAll.country,
        citie: valueAll.citie,
        district: valueAll.district,
        latitude,
        longitude,
        addressDedails: addressDedails && addressDedails.target.value,
      })
    );
  };
  let dataaddress = JSON.parse(localStorage.getItem("Address_desatils"));
  useEffect(() => {
    setstate(!dataaddress);
  }, []);
  const disabelBtn = () =>
    valueAll.country.length < 1 ||
    valueAll.district.length < 1 ||
    valueAll.citie.length < 1 ||
    !addressDedails;
  return (
    <>
      <section className="container-xxl ">
        <div
          //   onSubmit={hendling}
          className="  aut_form_with shadow-sm my-4 rounded-4"
        >
          <h3 className="fw-bold">{t("Shipping Information")}</h3>
          <div className="accordion " id="accordionPanelsStayOpenExample">
            <div className="accordion-item border-0">
              <div className="d-flex align-items-center justify-content-between">
                <span className="title-part">
                  {t("Select your location on the map")}
                </span>
                <ToggleIconArrow idaction={`#item_Location`} />
              </div>
              <div
                id="item_Location"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 py-3">
                  {/* subCategories */}
                  <Maping
                    longitude={longitude}
                    setlongitude={setlongitude}
                    latitude={latitude}
                    setlatitude={setlatitude}
                  />
                </div>
              </div>
              <hr />
            </div>
            <div className="accordion-item border-0">
              <div className="d-flex align-items-center justify-content-between">
                <span className="title-part">
                  {t("Select from saved locations")}
                </span>
                <ToggleIconArrow idaction={`#item_saved`} />
              </div>

              <div id="item_saved" className="accordion-collapse collapse show">
                <div className="accordion-body p-0"> </div>
              </div>

              <hr />
            </div>
          </div>
          <div className="row">
            <h5 className="my-2"> {t("New Location")}</h5>
            <div className="col-12">
              <InputSelect
                option={countriesOptions}
                required={true}
                value={valueAll.country}
                setvalue={getcountries}
                label={"country"}
                type={"text"}
                name={"country"}
                placeholder={t("--Select--")}
              />
            </div>
            <div className="col-12 col-sm-6">
              <InputSelect
                option={CitiesOptions}
                required={true}
                value={valueAll.Governrate}
                setvalue={getCities}
                label={"Governrate"}
                type={"text"}
                name={"Governrate"}
                placeholder={t("--Select--")}
              />
            </div>
            <div className="col-12 col-sm-6">
              <InputSelect
                option={districtsOptions}
                placeholder={t("--Select--")}
                required={true}
                value={valueAll.Governrate}
                setvalue={getdistricts}
                label={"districts"}
                type={"text"}
                name={"districts"}
              />
            </div>
            <InputTextArea
              required={false}
              value={addressDedails && addressDedails.target.value}
              setvalue={setaddressDedails}
              label={"Address"}
              name={"Address"}
            />
          </div>
          <div className="text-end">
            {" "}
            <button
              disabled={disabelBtn()}
              onClick={() => {
                saveAddress();
                setstate(false);
              }}
              className={`btn rounded-3 second_btn border-0  ${
                disabelBtn() && "text-black"
              } `}
            >
              {t("Save")}
            </button>
          </div>
        </div>
        <div className="text-center    aut_form_with     d-flex align-items-center  justify-content-between">
          <LinksCustom
            label={"Back"}
            path={"/CartPage"}
            classCustom={"btn rounded-3 second_btn border-0"}
          />
          <button
            disabled={state}
            onClick={handleNext}
            type="submit"
            className="btn rounded-3 main_btn"
          >
            {t("Confirm")}
          </button>
        </div>
      </section>
    </>
  );
}
