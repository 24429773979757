const initialState = {
    register: [],
    login: [],
    reset_password: [],
    forgot_password: [],
    products_categories: [],
    productsList: [],
    productDetails: [],
    Carts: [],
    sliderHome: [],
    bannerHome: [],
    favProducts: [],
    user_favProductList: [],
    QTY_favProductList: 0,
    WishList: [],
    AllproductsList: [],
    SplashSlider: [],
    All_categories: [],
    All_brands: [],
    countries: [],
    user_cities: [],
    user_districts: [],
    addorder: [],
    brand_1: [],
    brand_2: [],
    brand_3: [],
    brand_4: [],
    ValueSearch: '',
    trackingOrder: [],
    parants_categories: [],
    payment_gate_way: [],
    ConfirmPayment: []

}
export const reducers = (state = initialState, action) => {
    switch (action.type) {
        case "ConfirmPayment":
            return { ...state, ConfirmPayment: action.ConfirmPayment }
        case "payment_gate_way":
            return { ...state, payment_gate_way: action.payment_gate_way }
        case "parants_categories":
            return { ...state, parants_categories: action.parants_categories }
        case "ValueSearch":
            return { ...state, ValueSearch: action.ValueSearch }
        case "trackingOrder":
            return { ...state, trackingOrder: action.trackingOrder }
        case "register":
            return { ...state, register: action.register }
        case "addorder":
            return { ...state, addorder: action.addorder }
        case "countries":
            return { ...state, countries: action.paylod }
        case "brand_1":
            return { ...state, brand_1: action.paylod }
        case "brand_2":
            return { ...state, brand_2: action.paylod }
        case "brand_3":
            return { ...state, brand_3: action.paylod }
        case "brand_4":
            return { ...state, brand_4: action.paylod }
        case "user_cities":
            return { ...state, user_cities: action.paylod }
        case "user_districts":
            return { ...state, user_districts: action.paylod }
        case "login":
            return { ...state, login: action.login }
        case "reset_password":
            return { ...state, reset_password: action.reset_password }
        case "forgot_password":
            return { ...state, forgot_password: action.forgot_password }
        case "products_categories":
            return { ...state, products_categories: action.products_categories }
        case "Carts":
            return { ...state, Carts: action.Carts }
        case "productsList":
            return { ...state, productsList: action.productsList }
        case "productDetails":
            return { ...state, productDetails: action.productDetails }
        case "slider":
            return { ...state, sliderHome: action.paylod }
        case "banner":
            return { ...state, bannerHome: action.paylod }
        case "favProducts":
            return { ...state, favProducts: action.favProducts }
        case "WishList":
            return { ...state, WishList: action.WishList }
        case "AllproductsList":
            return { ...state, AllproductsList: action.AllproductsList }
        case "SplashSlider":
            return { ...state, SplashSlider: action.paylod }
        case "All_brands":
            return { ...state, All_brands: action.All_brands }
        case "All_categories":
            return { ...state, All_categories: action.All_categories }
        case "user_favProductList":
            return { ...state, user_favProductList: action.user_favProductList, QTY_favProductList: action.QTY_favProductList }
        default:
            return state
    }
}
