import { t } from "i18next";
import React from "react";
import { useEffect } from "react";
import { payment_gate_way } from "../../services/action";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { useNavigate } from "react-router-dom";
import SpinnerLoding from "../../lib/SpinnerLoding/SpinnerLoding";

export default function NotComplatePamment() {
  const dispatch = useDispatch();
  const dataUser = JSON.parse(localStorage.getItem("login"));
  const datapayment = queryString.parse(window.location.search);
  const navigate = useNavigate();
  useEffect(() => {
    if (datapayment) {
      dispatch(
        payment_gate_way(datapayment.order_uuid, dataUser.data.accessToken)
      );
    }
  }, []);
  const data = useSelector((state) => state.payment_gate_way);
  const go_to_order = () => {
    navigate(`/FollowTrackingOrder/${datapayment?.reference}`);
    window.location.href = window.location.href.split("?id=")[0];
    dispatch({ type: "ConfirmPayment", ConfirmPayment: [] });
  };
  return data && data.status ? (
    <div className="container mt-5">
      <div className=" shadow p-3 rounded-3 flex ">
        <h2 className="text-center py-4">
          {" "}
          {t("The payment process has not been completed")}
        </h2>
        <div className="mt-3 d-flex justify-content-between flex-wrap text-center  ">
          <a
            href={data.data.url}
            target="_self"
            className="btn main_btn mt-2 m-auto fs-5 p-1 lh-lg  "
          >
            {t("Payment")}
          </a>
          <button
            onClick={go_to_order}
            className="  second_btn fs-5 m-auto mt-2 rounded-3  py-2 "
          >
            {t("View Order Details")}
          </button>
        </div>
      </div>
    </div>
  ) : (
    <SpinnerLoding />
  );
}
