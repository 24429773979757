import './App.css';
import { useDispatch, useSelector } from 'react-redux';
import Navbar from './Layout/Navbar/Navbar';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import HomePage from './pages/HomePage/HomePage';
import Footer from './Layout/Footer/Footer';
import Login from './pages/Login/Login';
import RegisterNewCustomer from './pages/RegisterNewCustomer/RegisterNewCustomer';
import ProductPage from './pages/ProductPage/ProductPage';
import SplashPage from './pages/SplashPage/SplashPage';
import CartPage from './pages/CartPage/CartPage';
import ScrollToTop from './Layout/ScrollToTop/ScrollToTop';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import NewPassword from './pages/NewPassword/NewPassword';
import { useEffect, useState } from 'react';
import { AddCart } from './services/action';
import TokenLang from './Hook/TokenLang';
import ShippingInformation from './pages/ShippingInformation/ShippingInformation';
import FollowTrackingOrder from './pages/FollowTrackingOrder/FollowTrackingOrder';
import Categories from './pages/Categories/Categories';
import Wishlist from './pages/Wishlist/Wishlist';
import PrivateRoute from './Layout/PrivateRoute/PrivateRoute';
import NotComplatePamment from './pages/NotComplatePamment/NotComplatePamment';
import SuccessPayment from './pages/SuccessPage/SuccessPayment';

function App() {
  const dispatch = useDispatch()
  const [styleLink, setStyleLink] = useState(null)
  const [spashView, setspashView] = useState(true);
  useEffect(() => {
    dispatch(AddCart());
  }, [])
  const [token, lang] = TokenLang()
  useEffect(() => {
    if (lang === 'ar') {
      const link = document.createElement('link')
      link.rel = 'stylesheet'
      link.type = 'text/css'
      link.href = '/Ar.css'
      document.head.appendChild(link)
      setStyleLink(link)
    } else {
      if (styleLink) {
        document.head.removeChild(styleLink)
        setStyleLink(null)
      }
    }
  }, [lang]);

  // Check if current path is "/HomePage"

  return (
    <>
      <BrowserRouter>
        <ScrollToTop setspashView={setspashView} /> {/* Add the Scroll to Top component */}
        <Navbar />
        <section className='view_all_page'>
          <Routes>
            <Route path='/HomePage' element={<HomePage />} />
            <Route path='/' element={<SplashPage />} />
            <Route path='/Login' element={<Login />} />
            <Route path='/RegisterNewCustomer' element={<RegisterNewCustomer />} />
            <Route path='/Product/:id' element={<ProductPage />} />
            <Route path='/CartPage' element={<CartPage />} />
            <Route path='/ResetPassword' element={<ResetPassword />} />
            <Route path='/NewPassword' element={<NewPassword />} />
            <Route path='/Categories' element={<Categories />} />
            <Route path='/Wishlist' element={<PrivateRoute> <Wishlist /></PrivateRoute>} />
            <Route path='/ShippingInformation' element={<PrivateRoute> <ShippingInformation /></PrivateRoute>} />
            <Route path='/FollowTrackingOrder/:id' element={<FollowTrackingOrder />} />
            <Route path='/handle-payment/success' element={<SuccessPayment />} />
            <Route path='/handle-payment/cancel' element={<NotComplatePamment />} />
            <Route path='/handle-payment/declined' element={<NotComplatePamment />} />

          </Routes>
        </section>
        {spashView && <Footer />}
      </BrowserRouter>

    </>
  );
}

export default App;
