import React from "react";
import queryString from "query-string";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TokenLang from "../../Hook/TokenLang";
import SpinnerLoding from "../../lib/SpinnerLoding/SpinnerLoding";
import { t } from "i18next";
import { ConfirmPayment } from "../../services/action";
import { useNavigate } from "react-router-dom";
export default function SuccessPayment() {
  const [token, lang] = TokenLang();

  const datapayment = queryString.parse(window.location.search);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (datapayment) {
      dispatch(ConfirmPayment(datapayment.id, token, datapayment.payment_id));
    }
  }, []);
  const data = useSelector((state) => state.ConfirmPayment);

  if (data && data.status && datapayment) {
    navigate(`/FollowTrackingOrder/${datapayment.reference}`);
    window.location.href = window.location.href.split("?id=")[0];
    dispatch({ type: "ConfirmPayment", ConfirmPayment: [] });
  }
  return data?.status == 0 ? (
    <div className="text-center">
      <h2 className="text-center py-5">{data?.data} </h2>
      <button
        className="btn main_btn lh_lg"
        onClick={() => navigate("/HomePage")}
      >
        {t("Back To Home")}
      </button>
    </div>
  ) : (
    <div>
      <h4 className="pt-4 text-center">{t("Do not close the page")} </h4>
      <SpinnerLoding />
    </div>
  );
}
