import axios from "axios"

let header = {
    headers: {
        'secure-business-key': '927436464952ec1ad60.25905462',
        'apikey': 'efe2db322a53'
    }
}

// let ECOMMERCE_APi = "https://www.ecommerce.vodoglobal.com";
// let ACCOUNTING_APi = "https://accounting.vodoglobal.com";
let ECOMMERCE_APi = "https://testecommerce.vodoglobal.com";
let ACCOUNTING_APi = "https://test.vodoglobal.com";
let SECURE_BUSINESS_KEY = '927436464952ec1ad60.25905462'


export const register = (data) => {
    return async (dispatch) => {
        try {
            const reps = await axios.post(`${ACCOUNTING_APi}/api/business_users/register/v2`, data, {
                headers: {
                    'secure-business-key': SECURE_BUSINESS_KEY,
                    'apikey': 'efe2db322a53',
                }
            })
            dispatch({ type: "register", register: reps.data })
        } catch (error) {


        }


    }

}
export const payment_gate_way = (uuid, token) => {
    return async (dispatch) => {
        try {
            const reps = await axios.post(`${ACCOUNTING_APi}/api/payment-gate-way/telr/${uuid}`, {}, {
                headers: {
                    'secure-business-key': SECURE_BUSINESS_KEY,
                    'apikey': 'efe2db322a53',
                    "Authorization": `Bearer ${token}`
                }
            })
            dispatch({ type: "payment_gate_way", payment_gate_way: reps.data })
        } catch (error) {


        }


    }

}
export const login = (data, lang) => {
    return async (dispatch) => {
        try {
            const reps = await axios.post(`${ACCOUNTING_APi}/api/business_users/login`, data, {
                headers: {
                    'secure-business-key': SECURE_BUSINESS_KEY,
                    'apikey': 'efe2db322a53',
                    "Accept-Language": lang
                }
            })
            dispatch({ type: "login", login: reps.data })
        } catch (error) {


        }


    }

}
export const reset_password = (data) => {
    return async (dispatch) => {
        try {
            const reps = await axios.post(`${ACCOUNTING_APi}/api/business_users/reset_password`, data, header)
            dispatch({ type: "reset_password", reset_password: reps.data })
        } catch (error) {


        }


    }

}
export const ConfirmPayment = (id, user_token, payment_id) => {
    var config = {
        method: 'post',
        url: `${ACCOUNTING_APi}/api/ecommerce_payment/${id}?payment_id=${payment_id}&payment_type=telr`,
        headers: {
            'apikey': 'efe2db322a53',

            'secure-business-key': SECURE_BUSINESS_KEY,
            'user-token': user_token
        }
    };
    return async (dispatch) => {

        const res = await axios(config).then((response) => {
            return response.data
        })
        dispatch({ type: "ConfirmPayment", ConfirmPayment: res });
    }
}
export const forgot_password = (data) => {
    return async (dispatch) => {
        try {
            const reps = await axios.post(`https://accounting.vodoglobal.com/api/business_users/forgot_password`, data, header)
            dispatch({ type: "forgot_password", forgot_password: reps.data })
        } catch (error) {

        }
    }
}
export const products_categories = (lang, catigotyId) => {

    return async (dispatch) => {

        const res = await axios.get(`${ECOMMERCE_APi}/api/categories?page&pagination=false&type=child&category_id=${catigotyId ? catigotyId : ''}`, {
            headers: {
                'apikey': '5f28583f26a1a',
                'apiconnection': 'appmobile',
                'secure-business-key': SECURE_BUSINESS_KEY,
                "Accept-Language": lang
            }
        })
        dispatch({ type: "products_categories", products_categories: res.data });
    }
}
export const parants_categories = (lang) => {

    return async (dispatch) => {

        const res = await axios.get(`${ECOMMERCE_APi}/api/categories?page&pagination=false&type=parant`, {
            headers: {
                'apikey': '5f28583f26a1a',
                'apiconnection': 'appmobile',
                'secure-business-key': SECURE_BUSINESS_KEY,
                "Accept-Language": lang
            }
        })
        dispatch({ type: "parants_categories", parants_categories: res.data });
    }
}
export const All_categories = (lang, item, catigotyId) => {

    return async (dispatch) => {

        const res = await axios.get(`${ECOMMERCE_APi}/api/categories?page&pagination=true&paginate=${item}&category_id=${catigotyId ? catigotyId : ''}&type=child`, {
            headers: {
                'apikey': '5f28583f26a1a',
                'apiconnection': 'appmobile',
                'secure-business-key': SECURE_BUSINESS_KEY,
                "Accept-Language": lang
            }
        })
        dispatch({ type: "All_categories", All_categories: res.data });
    }
}

export const All_brands = (lang, item) => {

    return async (dispatch) => {

        const res = await axios.get(`${ECOMMERCE_APi}/api/brands?pagination=true&paginate=${item}`, {
            headers: {
                'apikey': '5f28583f26a1a',
                'apiconnection': 'appmobile',
                'secure-business-key': SECURE_BUSINESS_KEY,
                "Accept-Language": lang
            }
        })
        dispatch({ type: "All_brands", All_brands: res.data });
    }
}
export const brands = (lang, type, item) => {

    return async (dispatch) => {

        const res = await axios.get(`${ECOMMERCE_APi}/api/brands?parent_id=${item}`, {
            headers: {
                'apikey': '5f28583f26a1a',
                'apiconnection': 'appmobile',
                'secure-business-key': SECURE_BUSINESS_KEY,
                "Accept-Language": lang
            }
        })
        dispatch({ type: `brand_${type}`, paylod: res.data });
    }
}
export const countries = (lang) => {

    return async (dispatch) => {

        const res = await axios.get(`${ECOMMERCE_APi}/api/countries`, {
            headers: {
                'apikey': '5f28583f26a1a',
                'apiconnection': 'appmobile',
                'secure-business-key': SECURE_BUSINESS_KEY,
                "Accept-Language": lang
            }
        })
        dispatch({ type: "countries", paylod: res.data });
    }
}
export const user_cities = (lang, id) => {

    return async (dispatch) => {

        const res = await axios.get(`${ECOMMERCE_APi}/api/user_cities?country_id=${id}`, {
            headers: {
                'apikey': '5f28583f26a1a',
                'apiconnection': 'appmobile',
                'secure-business-key': SECURE_BUSINESS_KEY,
                "Accept-Language": lang
            }
        })
        dispatch({ type: "user_cities", paylod: res.data });
    }
}
export const user_districts = (lang, id) => {
    return async (dispatch) => {
        const res = await axios.get(`${ECOMMERCE_APi}/api/user_districts/v2?city_id=${id}`, {
            headers: {
                'apikey': '5f28583f26a1a',
                'apiconnection': 'appmobile',
                'secure-business-key': SECURE_BUSINESS_KEY,
                "Accept-Language": lang
            }
        })
        dispatch({ type: "user_districts", paylod: res.data });
    }
}
export const addOrder = (data, user_token) => {

    return async (dispatch) => {

        const res = await axios.post(`${ECOMMERCE_APi}/api/v2/orders`, data, {
            headers: {
                'apikey': '5f28583f26a1a',
                'apiconnection': 'appmobile',
                'user-token': user_token,
                "secure-business-key": SECURE_BUSINESS_KEY

            }

        })


        dispatch({ type: "addorder", addorder: res.data });
    }
}

export const productsList = (lang, user_token, product_kind) => {

    return async (dispatch) => {

        const res = await axios.get(`${ECOMMERCE_APi}/api/products?show_type=homepage&paginate=8&page&pagination=false&&category_id=${product_kind}`, {
            headers: {
                apikey: "5f28583f26a1a",
                apiconnection: "appmobile",
                'secure-business-key': SECURE_BUSINESS_KEY,
                'user-token': user_token,
                "Accept-Language": lang
            }
        }).then(resp => resp)
        dispatch({ type: "productsList", productsList: res.data });
    }
}
export const AllproductsList = (lang, user_token, page, filter) => {
    const brandIds = JSON.stringify(filter && filter.brand_id); // Convert to JSON string

    return async (dispatch) => {
        const res = await axios.get(`${ECOMMERCE_APi}/api/products?paginate=12&pagination=true&page=${page}&keyword=${filter && filter.keyword}&category_id=${filter && filter.category_id}&brand_id=${brandIds}`, {
            headers: {
                apikey: "5f28583f26a1a",
                apiconnection: "appmobile",
                'secure-business-key': SECURE_BUSINESS_KEY,
                'user-token': user_token,
                "Accept-Language": lang
            }
        }).then(resp => resp)
        dispatch({ type: "AllproductsList", AllproductsList: res.data });
    }
}
export const productDetails = (lang, user_token, id) => {

    return async (dispatch) => {

        const res = await axios.get(`${ECOMMERCE_APi}/api/products/details/${id}`, {
            headers: {
                apikey: "5f28583f26a1a",
                apiconnection: "appmobile",
                'secure-business-key': SECURE_BUSINESS_KEY,
                'user-token': user_token,
                "Accept-Language": lang
            }
        }).then(resp => resp)
        dispatch({ type: "productDetails", productDetails: res.data });
    }
}
export const AddfavProducts = (user_token, data) => {

    return async (dispatch) => {

        const res = await axios.post(`${ECOMMERCE_APi}/api/products/favourites`, data, {
            headers: {
                apikey: "5f28583f26a1a",
                apiconnection: "appmobile",
                'user-token': user_token,
                'secure-business-key': SECURE_BUSINESS_KEY
            }
        }).then(resp => resp)
        dispatch({ type: "favProducts", favProducts: res.data });
    }
}
export const deleteItemfavProducts = (user_token, id) => {

    return async (dispatch) => {

        const res = await axios.delete(`${ECOMMERCE_APi}/api/products/favourites/${id}`, {
            headers: {
                apikey: "5f28583f26a1a",
                apiconnection: "appmobile",
                'user-token': user_token,
                'secure-business-key': SECURE_BUSINESS_KEY
            }
        }).then(resp => resp)
        dispatch({ type: "favProducts", favProducts: res.data });
    }
}
export const user_favProductList = (lang, user_token) => {

    return async (dispatch) => {

        const res = await axios.get(`${ECOMMERCE_APi}/api/products/favourites
        `, {
            headers: {
                apikey: "5f28583f26a1a",
                apiconnection: "appmobile",
                'secure-business-key': SECURE_BUSINESS_KEY,
                'user-token': user_token,
                "Accept-Language": lang
            }
        }).then(resp => resp)
        dispatch({ type: "user_favProductList", user_favProductList: res.data, QTY_favProductList: res.data.data.length });
    }
}
export const sliders_and_banners = (lang, user_token, type, group) => {

    return async (dispatch) => {

        const res = await axios.get(`${ECOMMERCE_APi}/api/sliders_and_banners?type=${type === "SplashSlider" ? 1 : 0}&group=${group}`, {
            headers: {
                apikey: "5f28583f26a1a",
                apiconnection: "appmobile",
                'secure-business-key': SECURE_BUSINESS_KEY,
                'user-token': user_token,
                "Accept-Language": lang
            }
        }).then(resp => resp)

        dispatch({ type: type, paylod: res.data });
    }

}
export const trackingOrder = (reference, user_token, lang) => {

    return async (dispatch) => {

        const res = await axios.get(`${ECOMMERCE_APi}/api/orders/${reference}/tracking`, {
            headers: {
                'apikey': '5f28583f26a1a',
                'apiconnection': 'appmobile',
                'secure-business-key': SECURE_BUSINESS_KEY,
                'user-token': user_token,
                "Accept-Language": lang
            }

        }).then((response) => {
            return response.data
        })
        dispatch({ type: "trackingOrder", trackingOrder: res });
    }
}
export const AddCart = (value) => {
    value && localStorage.setItem("Carts", JSON.stringify(value))
    const data = JSON.parse(localStorage.getItem("Carts"))
    return (dispatch) => {
        dispatch({ type: "Carts", Carts: data })
    }
}
export const Move_To_Wish_List = (value) => {
    value && localStorage.setItem("WishList", JSON.stringify(value))
    const data = JSON.parse(localStorage.getItem("WishList"))
    return (dispatch) => {
        dispatch({ type: "WishList", WishList: data })
    }
}
export const ValueSearch = (value) => {
    return (dispatch) => {
        dispatch({ type: "ValueSearch", ValueSearch: value })
    }
}